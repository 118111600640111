.noticeList{
    border-top: 2px solid #005b8f;
    border-bottom: 1px solid #e0e0e0;
    .headTit{
        font-size: 16px;
        font-weight: 500;
    }
    @media (max-width: 780px) {
        .bodyTit{
            font-size: 14px !important;
        }
    }
    @media (max-width: 400px) {
        .headTit{
            font-size: 12px !important;
            font-weight: 500;
        }
        .bodyTit{
            font-size: 13px !important;
        }
    }
}