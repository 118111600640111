.termDownloadBox{
    margin-top: 60px;
    text-align: center;
    h1{
        font-size: 25px;
        font-weight: 700;
    }
    .tit{
        font-size: 18px;
        font-weight: 700;
        display: inline-block;
        margin-top: 40px;
    }
    .reconfirmBox{
        margin-top: 60px;
        background-color: #f1f1f1;
        border-radius: 4px;
        padding: 40px;
        p{ 
            margin-bottom: 40px;
        }
        .inputTit{
            font-weight: 700;
            margin-right: 15px;
        }
        .emailInput{
            margin-right: 20px;
        }
        .emailInput:focus{
            outline: none;
    
    
        }
        .selectBox{
            display: inline-block;
            width: 200px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            outline: none;
            margin-top: 7.5px;
            line-height: 43px;
            height: 43px;
            margin-left: 25px;
            background-color: #fff;
            fieldset{
                visibility: hidden;
            }
        }
        .reconfirmBtn{
            cursor: pointer;
            margin-top: 20px;
            display: inline-block;
            padding: 15px 50px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
       
    }
    .downloadPage{
        margin-top: 40px;
        margin-bottom: 160px;
        .txt{
            margin-top: 20px;
        }
        .applyBtn{
            cursor: pointer;
            margin-top: 20px;
            display: inline-block;
            padding: 15px 50px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
    }
   
  
}
@media (max-width: 780px) {
    .termDownloadBox{
        text-align: left !important;
        h1{
            margin-left: 20px !important;
        }
        .tit{
            margin-left: 20px !important;
        }
        .reconfirmBox{
            p{ 
                font-size: 14px !important;
            }
            line-height: 25px;
            padding: 40px 20px !important;
            .inputTit{
                font-weight: 700;
                margin-right: 10px;
            }
            .emailInput{
                width: 33%;
                margin-right: 15px;
            }
            .selectBox{
                width: 38% !important;
                margin-left: 15px;
            }
            .reconfirmBtn{
                margin-left: 65px;
                width: 80%;
                display: block;
                padding: 10px 0 !important;
                text-align: center;
            }
           
        }
        .downloadPage{
            margin-top: 40px;
            margin-bottom: 160px;
            text-align: center;
            .txt{
                padding: 0 40px;
                margin-top: 20px;
                line-height: 25px;
                font-size: 14px;
            }
            .applyBtn{
                margin-left: 20px;
                margin-top: 20px;
                display: inline-block;
                text-align: center;
                padding: 15px 100px !important;
                border-radius: 4px;
                color: #fff;
                font-weight: 700;
                background-color: #005b8f;
            }
        }
    }
}
@media (max-width: 400px) {
    .termDownloadBox{
        h1{
            margin-left: 10px !important;
            font-size: 22px !important;
        }
        .tit{
            margin-left: 10px !important;
            font-size: 16px !important;
        }
        .reconfirmBox{
            padding: 30px 15px !important;
            p{ 
                width: 317px;
                margin-bottom: 20px !important; 
            }
            .inputTit{
                display: block !important;
                font-size: 16px;
                margin-right: 0 !important;
            }
            .emailInput{
                margin-left: 0 !important;
                width: 44% !important;
                margin-right: 5px;
            }
            .selectBox{
                height: 45px !important;
                width: 49% !important;
                margin-left: 5px;
            }
            .reconfirmBtn{
                margin-left: 0 !important;
                width: 100% !important;
            }
           
        }
        .downloadPage{
            margin-top: 40px !important;
            margin-bottom: 160px !important;
            text-align: center;
            .tit{
                margin-left: 0 !important;
            }
            .applyBtn{
                margin-left: 0 !important;
            }
        }
    }
}
.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 44px;
    
}
.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3px 4px 7.5px 6px !important;
}


