.footer {
  padding: 20px 20px 50px;
  border-top: 1px solid #ccc;
  .inner {
    position: relative;
    .imgLogoBox {
      .imgBox1 {
        margin-left: 30px;
        width: 135px;
        display: inline-block;
        img {
          width: 100%;
        }
      }
      .imgBox2 {
        width: 200px;
        vertical-align: -14px;
        display: inline-block;
        img {
          width: 100%;
        }
      }
    }
    .txtBox {
      margin-top: 20px;
      display: inline-block;
      p {
        font-size: 14px;
        display: inline-block;
      }
      .mid {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .bttom {
        .txt {
          display: block;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
}
@media (max-width: 780px) {
  .footer {
    margin-top: 50px;
    padding: 20px 30px 50px;
    .imgBox {
      width: 120px;
      img {
        width: 100%;
      }
    }
    .imgBox2 {
      vertical-align: -15px;
    }
    .txtBox {
      p {
        font-size: 12px;
      }
      .rigTxt {
        margin-top: 5px;
      }
    }
    .imgBox {
      position: inherit !important;
      display: block !important;
      margin-top: 30px;
    }
  }
}
@media (max-width: 400px) {
  .footer {
    padding: 10px !important;
    .imgBox {
      width: 100px;
      img {
        width: 100%;
      }
    }
    .imgBox2 {
      vertical-align: -10px;
      margin-left: 1px !important;
    }
    .txtBox {
      margin-top: 10px;
      p {
        font-size: 10px;
      }
    }
  }
}
