.applicationBox{
    text-align: center;
    margin-bottom: 160px;
    width: 80%;
    margin: 0 auto;
    .infoBox{
        text-align: left;
        background-color: #f1f1f1;
        padding: 40px;
        border-radius: 4px;
        .tit{
            font-size: 20px;
            font-weight: 700;
        }
        .txtBox{
            margin-top: 20px;
        }
    }
    .privacyBox{
        text-align: left;
        margin-top: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 40px;
        .tit{
            font-size: 25px;
            font-weight: 700; 
        }
        .txtBox{
            margin-top: 20px;
            p{
                line-height: 25px;
            }
        }
    }
    .ckBox{
        margin-top: 20px;
        text-align: right;

    }
    .privacyInfoBox{
        background-color: #f1f1f1;
        padding: 40px 20px;
        margin-top: 60px;
        border-radius: 4px;
        margin-bottom: 160px;
       
        .inputListBox{
            display: inline-block;
            text-align: left;
            .inputTit{
                vertical-align: 18px;
                font-weight: 700;
                margin-right: 15px;
            }
            .emailInput{
                margin-right: 27px;
            }
            .emailInput:focus{
                outline: none;
            }
            .divisionSelectBox{
                display: inline-block;
                width: 215px;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                outline: none;
                margin-top: 7.5px;
                line-height: 43px;
                height: 43px;
                margin-left: 25px;
                background-color: #fff;
                fieldset{
                    visibility: hidden;
                }
            }
            .companyInputTit{
                margin-left: 16px;
                vertical-align: 18px;
                font-weight: 700;
                margin-right: 8px;
            }
            .companyBox{
                width: 200px;
            }
            .selectBox{
                height: 45px;
                display: inline-block;
                width: 200px;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                outline: none;
                margin-top: 7.5px;
                line-height: 43px;
                margin-left: 32px;
                background-color: #fff;
                fieldset{
                    visibility: hidden;
                }
            }
        }
        .completeBtn{
            cursor: pointer;
            margin-top: 40px;
            display: inline-block;
            padding: 15px 50px;
            margin-left: 60px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
    }
    
    
}
.title{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 60px;
    text-align: center;
}
@media (max-width: 1100px) {
    .applicationBox{
        width: 90% !important;
        .infoBox{
            padding: 25px !important;
        }
        .privacyBox{
            padding: 25px !important;
            .tit{
                font-size: 20px !important;
                font-weight: 700; 
            }
            .txtBox{
                p{
                    font-size: 16px !important;
                }
            }
        }
        .privacyInfoBox{
            text-align: left;
            padding: 40px 25px !important;
            .inputListBox{
                text-align: left;
                width: 70%;
                .inputTit{
                    margin-right: 15px;
                    vertical-align: 18px;
                }
                .nameInput{
                    width: 35%;
                }
                .divisionSelectBox{
                    width: 35% !important;
                }
                .companyBox{
                    width: 32% !important;
                    margin-right: 0 !important;
                }
                .emailInput{
                    margin-right: 15px;
                    width: 35%;
                }
                .selectBox{
                    width: 38%;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    outline: none;
                    margin-top: 7.5px;
                    line-height: 43px;
                    margin-left: 15px;
                    background-color: #fff;
                }
            }
            .completeBtn{
                cursor: pointer;
                margin-top: 40px;
                display: inline-block;
                padding: 15px 50px;
                margin-left: 20px !important;
                border-radius: 4px;
                color: #fff;
                font-weight: 700;
                background-color: #005b8f;
            }
        }
        
        
    }
}
@media (max-width: 780px) {
    .applicationBox{
        width: 95% !important;
        .infoBox{
            padding: 30px 20px !important;
            .txtBox{
                line-height: 24px;
            }
        }
        .privacyBox{
            padding: 30px 20px !important;
            .tit{
                font-size: 18px !important;
            }
            .txtBox{
                p{
                    line-height: 22px !important;
                    font-size: 14px !important;
                }
            }
        }
        .ckBox{
            font-size: 14px ;
    
        }
        .privacyInfoBox{
            padding: 30px 10px !important;
            .inputListBox{
                width: 100%;
                .inputTit{
                    margin-right: 5px !important;
                    vertical-align: 18px !important;
                }
                .nameInput{
                    width: 35% !important;
                }
                .emailInput{
                    margin-right: 10px !important;
                    width: 35%;
                }
                .companyInputTit{
                    margin-left: 14px !important;
                    margin-right: 0px !important;
                }
                .selectBox{
                    width: 40%;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    outline: none;
                    margin-top: 7.5px !important;
                    line-height: 43px;
                    margin-left: 7px !important;
                    background-color: #fff;
                }
            }
            .completeBtn{
                margin-top: 20px !important;
                margin-left: 60px !important;
                display: block;
                width: 82% !important;
                padding: 15px 0px !important;
                text-align: center;
            }
        }
        
        
    }
}
@media (max-width: 400px) {
    .applicationBox{
        width: 95% !important;
        .infoBox{
            .tit{
                font-size: 18px;
            }
            .txtBox{
                font-size: 14px;
            }
        }
        .privacyBox{
            .tit{
                font-size: 18px !important;
            }
        }
        .privacyInfoBox{
            .inputListBox{
                width: 100%;
                .inputTit{
                    margin-right: 5px;
                    margin-bottom: 5px;
                    display: block;
                }
                .emailTit{
                    margin-top: 15px;
                }
                .nameInput{
                    margin-left: 0 !important;
                    width: 100% !important;
                    margin-bottom: 15px;
                }
                .divisionSelectBox{
                    display: block;
                    margin-left: 0 !important;
                    width: 100% !important;
                    margin-bottom: 20px;
                }
                .companyInputTit{
                    margin-left: 0 !important;
                }
                .companyBox{
                    width: 100% !important;
                    margin-left: 0 !important;
                }
                .emailInput{
                    margin-right: 5px !important;
                    margin-left: 0 !important;
                    width: 41.5%;
                    display: inline-block;
                }
                .selectBox{
                    height: 45px;
                    width: 51%;
                    margin-left: 5px !important;
                }
            }
            .completeBtn{
                margin-top: 20px !important;
                margin-left: 0 !important;
                display: block;
                width: 100% !important;
                padding: 15px 0px !important;
                text-align: center;
            }
        }
        
        
    }
    .title{
        font-size: 22px;
        margin-bottom: 40px !important;
    }
}
.css-1na68kg .MuiOutlinedInput-root{
    padding: 9px 9px 0px !important;
}
.css-1na68kg .MuiOutlinedInput-root .MuiAutocomplete-input{
    padding: 5px 4px 5px 6px !important;
}