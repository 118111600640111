/* font */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
h1,h2,h3,h4,p{margin: 0; padding: 0;}

/* color */
.red{
  color: red;
  margin-left: 2px;
}
/* Link */
a{
  color: #1b1b1b;
  text-decoration: none;
  display: block;
}
/* float */
.fl_lef{
  float: left;
}
.fl_rig{
  float: right;
}
.inbk{
  display: inline-block;
}

/* align */
.tx_lef{
  text-align: left;
}
.tx_rig{
  text-align: right;
}
.tx_cen{
  text-align: center;
}

/* cursor */
.cur_point{
  cursor: pointer;
}
.cur_none{
  cursor: none;
}

.inner{
  max-width: 1200px !important; 
  margin: 0 auto;
  letter-spacing: -0.5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
}

/* margin */

.mar_cen{
  margin : 0 auto;
}
.mar_5{
  margin-top: 5px;
}
.mar_10{
  margin-top: 10px;
}
.mar_15{
  margin-top: 15px;
}
.mar_20{
  margin-top: 20px;
}
.mar_30{
  margin-top: 30px;
}
.mar_40{
  margin-top: 40px;
}
.mar_50{
  margin-top: 50px;
}
.mar_60{
  margin-top: 60px;
}
.mar_70{
  margin-top: 70px;
}
.mar_80{
  margin-top: 80px;
}
.mar_90{
  margin-top: 90px;
}
.mar_100{
  margin-top: 100px;
}


/* noneBox */
.flBox{
  height: 100%;
}
.flBox::after{
  content: "";
  display: block;
  clear: both;
}
.noneBox_10 {
  height: 10px;
}
.noneBox_20 {
  height: 20px;
}
.noneBox_30 {
  height: 30px;
}
.noneBox_50 {
  height: 50px;
}
.noneBox_60 {
  height: 60px;
}
.noneBox_70 {
  height: 70px;
}
.noneBox_80 {
  height: 80px;
}
.noneBox_100 {
  height: 100px;
}
.noneBox_110 {
  height: 110px;
}
.noneBox_120 {
  height: 120px;
}
.noneBox_130 {
  height: 130px;
}
.noneBox_140 {
  height: 140px;
}
.noneBox_150 {
  height: 150px;
}
.noneBox_170 {
  height: 170px;
}
.noneBox_180 {
  height: 180px;
}
.noneBox_200 {
  height: 200px;
}
.noneBox_250 {
  height: 250px;
}
.noneBox_300 {
  height: 300px;
}
.noneBox_350 {
  height: 350px;
}
.noneBox_600 {
  height: 600px;
}

/* Loading */
.loadBox{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgb(239 239 239 / 50%);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 1;
}

/* paging */
.pagination {
  position: absolute;
  justify-content: center; 
  margin-top: 15px; 
  padding-bottom: 15px;
} 
ul { 
  list-style: none; 
  padding: 0; 
} 
ul.pagination li { 
  display: inline-block; 
  border-radius: 50%;
  margin: 0 5px;
  width: 35px; 
  height: 35px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  font-size: 1rem; 
} ul.pagination li:first-child{ 
  border-radius: 5px 0 0 5px; display: none; 
} ul.pagination li:last-child{ 
  border-radius: 0 5px 5px 0; display: none; 
}ul.pagination li:nth-child(2){ 
  a{
    font-size: 12px !important;
    font-weight: 700;
    color: #666;
  }
}ul.pagination li:nth-child(8){ 
  a{
    font-size: 12px !important;
    font-weight: 700;
    color: #666;
  }
} ul.pagination li a { 
  text-decoration: none; 
  color: black; 
  font-size: 1rem; 
} 
ul.pagination li.active a {
  color: black; 
} 
ul.pagination li.active { 
  background-color: #f7f7f7;  border: 1px solid #e2e2e2; 
} 
ul.pagination li a:hover, ul.pagination li a.active { 
  color: rgb(95, 95, 95); 
} 
.page-selection { 
  width: 48px; 
  height: 30px; 
  color: black; 
}

/* ETC */
.btn-check:active+.btn-warning:focus, .btn-check:checked+.btn-warning:focus, .btn-check:focus+.btn-warning, .btn-outline-secondary, .btn-warning.active:focus, .btn-warning:active:focus, .btn-warning:focus, .show>.btn-warning.dropdown-toggle:focus {
  box-shadow: none !important;
}
.tempBorder {
  border: .1em solid #d6d6d6;
}
.fileIn {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.inputBox{
  position: relative;
  display: inline-block;
  width: 100%;
  .searchInput{
      display: inline-block;
      width: 100%;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      outline: none;
      padding-left: 20px;
  }
  .searchIcon{
      position: absolute;
      right: 20px;
      top: 7px;
      color: #005b8f;
      font-size: 35px;
  }
}

@media (max-width: 400px) {
  .inputBox{
    font-size: 14px !important;
    .searchInput{
        padding-left: 15px !important;
    }
  }
}

//swiper 
.swiper-button-next, .swiper-button-prev{
  color: #666 !important;
}
.css-177ic5c{
  box-shadow: none !important;
}
.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded{
  box-shadow: none !important;
}
