.main{
    margin-top: 60px;
    text-align: center;
    margin-bottom: 160px;
    legend,fieldset{line-height: 0 !important; visibility: hidden;}
    .noticeListBox{
        width: 40%;
        display: inline-block; 
        .btmBox{
            margin-top: 20px;
            text-align: right;
            .totalListBox{
                vertical-align: top;
                height: 50px;
                width: 150px;
                display: inline-block;
                border-radius: 5px;
                border: 1px solid #e0e0e0;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                span{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 700;
                    color: #005b8f;
                }
            }
            .faqListBox{
                height: 85px;
                width: 47%;
                display: inline-block;
                background-color: #005b8f;
                border-radius: 4px;
                line-height: 85px;
                color: #fff;
                font-weight: 700;
                font-size: 26px;
                cursor: pointer;
            }
        }
    }
    .suchBarBox{
        margin-bottom: 50px;
        .searchSelectBox{
            border: 1px solid #e0e0e0;
            background-color: #fff;
            margin: 0;
            width: 150px;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            .searchSelect{
                height: 50px;
            }
        }
        .classificationBox{
            border: 1px solid #e0e0e0;
            background-color: #fff;
            margin: 0;
            width: 150px;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            .searchSelect{
                height: 50px;
            }
        }
        .searchBox{
            width: 650px;
            display: inline-block;
        }
        .searchBtn{
            cursor: pointer;
            width: 80px;
            height: 50px;
            display: inline-block;
            background-color: #005b8f;
            border-radius: 4px;
            vertical-align: top;
            color: #fff;
            line-height: 50px;
            font-weight: 700;
            margin-left: 10px;
        }
    }
    .infoTxtBox{
        margin: 40px 0 55px 0;
        .tit{
            font-size: 25px;
            font-weight: 700;
        }
        .txtBox{
            line-height: 30px;
            font-size: 20px;
            margin-top: 20px;
        }
    }
    .lfBox{
        margin-right: 50px;
        vertical-align: top;
        display: inline-block;
        .termsDownloadBox{
            background-color: #f1f1f1;
            border-radius: 4px;
            width: 530px;
            padding: 40px 0;
            text-align: center;
            .tit{
                margin-top: 10px;
                font-size: 18px;
                font-weight: 700;
                display: inline-block;
            }
            .txt{
                margin-top: 35px;
                line-height: 25px;
                display: inline-block;
            }
            .applyBtn{
                vertical-align: 13px;
                cursor: pointer;
                margin-top: 35px;
                display: inline-block;
                width: 60%;
                padding: 15px;
                border-radius: 4px;
                color: #fff;
                font-weight: 700;
                background-color: #005b8f;
            }
        }
      
    }
    @media (max-width: 1100px) {
        .searchSelectBox{
            width: 15% !important;
            .searchSelect{
                font-size: 12px !important;
            }
        }
        .classificationBox{
            width: 15% !important;
        }
        .searchBox{
            width: 55% !important;
        }
        .noticeListBox{
            margin-top: 40px;
            width: 560px;
            display: inline-block; 
        }
        .lfBox{
            margin-right: 0 !important;
        }
    }
    @media (max-width: 780px) {
        .searchSelectBox{
            width: 22% !important;
        }
        .searchSelect{
            font-size: 12px !important;
        }
        .classificationBox{
            width: 20% !important;
        }
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
            font-size: 14px;
        }
        .searchBox{
            width: 50% !important;
        }
        .searchBtn{
            margin-left: 0px !important;
            margin-top: 15px;
            width: 96% !important;
            display: inline-block !important;
        }
        .termsDownloadBox{
            width: 100% !important;
            padding: 20px 90px 20px !important;
            text-align: center !important;
            .tit{
                font-size: 16px !important;
            }
            .applyBtn{
                margin-left: 15px !important;
                padding: 15px 40px !important;
            }
        }
        .infoTxtBox{
            .tit{
                font-size: 22px !important;
            }
            .txtBox{
                line-height: 25px !important; 
                font-size: 18px !important;
            }
        }
        // .totalListBox{
        //     height: 60px !important;
        //     width: 90% !important;
        //     line-height: 60px !important;
        //     span{
        //         font-size: 20px !important;
        //     }
        // }
        .faqListBox{
            height: 60px !important;
            width: 45% !important;
            line-height: 60px !important;
            font-size: 20px !important;
        }
        .noticeListBox{
            width: 460px;
        }
    }
    @media (max-width: 400px) {
        // .suchBarBox{
        //     width: 350px !important;
        // }
        .searchSelectBox{
            margin-left: 0 !important;
            width: 130px !important;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            .searchSelect{
                height: 50px;
            }
        }
        .classificationBox{
            margin-left: 0 !important;
            width: 150px !important;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            .searchSelect{
                height: 50px;
            }
        }
        .searchBox{
            margin-top: 7px;
            width: 330px !important;
            display: inline-block;
        }
        .searchBtn{
            margin-top: 15px;
            width: 380px !important;
            display: inline-block !important;
            color: #fff;
            text-align: center;
        }
        .termsDownloadBox{
            padding: 0 20px 20px !important;
            width: 350px !important;
            .tit{
                margin-top: 25px !important;
                font-size: 16px !important;
            }
            .txt{
                font-size: 14px;
                line-height: 20px !important;
            }
            .applyBtn{
                margin-left: 0 !important;
                padding: 10px 50px !important;
            }
        }
        .totalListBox{
            height: 50px !important;
            width: 100% !important;
            line-height: 50px !important;
            span{
                font-size: 18px !important;
            }
        }
        .faqListBox{
            height: 50px !important;
            width: 45% !important;
            line-height: 52px !important;
            font-size: 18px !important;
        }
        .noticeListBox{
            width: 350px;
        }
    }  
}

    