.faqBox{
    float: left;
    margin-top: 15px;
    margin-left: 40px;
    width: 55%;
    margin-bottom: 120px;
    h2{
        font-size: 25px;
        margin: 35px 0px;
        font-weight: 700;
    }
    .faqListBox{
        border-top: 2px solid #005b8f;
        .faqList{
            box-shadow: none !important;
            border-radius: 0 !important;
            border-top: 1px solid #e0e0e0;
            .QuestionTit{
                display: block;
                font-weight: 700;
                margin: 5px 0;
                span{
                    font-size: 20px;
                    color: #005b8f;
                    font-weight: 700;
                }
            }
            .QuestionTxt{
                border-bottom: 1px solid #e0e0e0;
                min-height: 80px;
                padding-left: 16px;
            }
            .askTit{
                margin-top: 20px;
                font-weight: 700;
                span{
                    font-size: 20px;
                    color: #005b8f;
                    font-weight: 700;
                }
            }
            .askTxt{
                
                min-height: 150px;
            }
        }
    }
}
.inner::after{
    content: "";
    display: block;
    clear: both;
}
@media (max-width: 1100px) {
    h2{
        font-size: 20px !important;
    }

}
@media (max-width: 780px) {
    h2{
        font-size: 18px !important;
        
    }
    .faqBox{
        padding: 0 10px;
        float: none !important;
        width: 100% !important;
        margin-left: 0 !important;
        h2{
            margin-left: 20px !important;
        }
    }
}
@media (max-width: 400px) {
    .faqBox{
        h2{
            margin-left: 10px !important;
        }
    }
}