.noticeBox{
    float: left;
    margin-top: 15px;
    margin-left: 40px;
    width: 55%;
    h2{
        font-size: 25px;
        margin: 35px 0;
        font-weight: 700;
    }
}
.inner::after{
    content: "";
    display: block;
    clear: both;
}
@media (max-width: 1100px) {
    h2{
        font-size: 20px !important;
    }
}
@media (max-width: 780px) {
    .noticeBox{
        padding: 0 10px;
        float: none !important;
        width: 100% !important;
        margin-left: 0 !important;
        min-height: 430px;
        h2{
            margin-left: 10px !important;
        }
    }
}
@media (max-width: 400px) {
    .noticeBox{
        h2{
            margin-left: 20px !important;
            font-size: 18px !important;
        }
    }
}