.termsListBox{
    margin-top: 60px;
    margin-bottom: 120px;
    legend,fieldset{line-height: 0 !important; visibility: hidden;}
    h1{
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }
    .termSearchBox{
        margin-top: 20px;
        text-align: center;
        padding: 40px 0;
        border-radius: 4px;
        .searchSelectBox{
            border: 1px solid #e0e0e0;
            background-color: #fff;
            margin: 0;
            width: 150px;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            
            .searchSelect{
                height: 50px;
            }
        }
        .classificationBox{
            border: 1px solid #e0e0e0;
            background-color: #fff;
            margin: 0;
            width: 150px;
            height: 50px;
            border-radius: 4px;
            margin-right: 10px;
            
            .searchSelect{
                height: 50px;
            }
        }
        .searchBox{
            width: 600px;
            display: inline-block;
        }
        .searchBtn{
            cursor: pointer;
            width: 80px;
            height: 50px;
            display: inline-block;
            background-color: #005b8f;
            border-radius: 4px;
            vertical-align: top;
            color: #fff;
            line-height: 50px;
            font-weight: 700;
            margin-left: 10px;
        }
        .wordList{
            margin-top: 40px;
            text-align: center;
            p{
                text-align: center;
                display: inline-block;
            }
            .all{
                cursor: pointer;
                font-size: 20px;
                font-weight: 500;
                vertical-align: 13px;
            }
            .wordTit{
                font-size: 16px;
                font-weight: 500;
                color: #666;
                margin-bottom: 20px;
                font-size: 14px;
            }
            .consonantBox{
                line-height: 30px;
                .korWord{
                    .word{
                        margin-bottom: 10px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 50%;
                        background-color: #fff;
                        cursor: pointer;
                        margin-left: 10px;
                        font-size: 16px;
                        border: 1px solid #e0e0e0;
                        font-weight: 700;
                        transition: all .3s ease;
                    }
                }
                .engWord{
                    .word{
                        margin-bottom: 10px;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #fff;
                        cursor: pointer;
                        margin-left: 10px;
                        font-size: 16px;
                        border: 1px solid #e0e0e0;
                        font-weight: 500;
                        transition: all .3s ease;
                    }
                }
                .word:hover{
                    background-color: #005b8f;
                    color: #fff;
                }
                
            }
            p.on{
                color: #005b8f;
                font-weight: 700;
            }
        }
    }
    .termListBox{
        text-align: center;
        background-color: #f1f1f1;
        padding-top: 40px;
        border-radius: 4px;
        min-height: 770px;
        position: relative;
        .termList{
            cursor: pointer;
            text-align: left;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 20px;
            display: inline-block;
            width: 1000px;
            height: 100px;
            line-height: 60px;
            padding: 20px;
            margin-bottom: 20px;
            .wordBox{
                width: 35px;
                height: 35px;
                display: inline-block;
                font-weight: 700;
                font-size: 20px;
                background-color: #005b8f;
                border-radius: 50%;
                position: relative;
                vertical-align: -11px;
                span{
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit{
                width: 65%;
                display: inline-block;
                font-size: 18px;
                font-weight: 700;
                margin-left: 15px;
                padding-right: 15px;
                position: relative;
                //border-right: solid 1px #eaeaea;
                &::before{
                    content: "";
                    display: block;
                    width: 1px;
                    height: 25px;
                    background-color: #e0e0e0;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .txt{
                padding-left: 6%;
                display: inline-block;
                font-size: 14px;
                margin-left: 15px;
                vertical-align: 4px;
            }
        }
        .pagination{
            position: absolute !important;
            bottom: 0 !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
        }
        
    }
    @media (max-width: 1100px) {
        .searchBox{
            width: 45% !important;
        }
        .termList{
            width: 90% !important;
            .wordBox{
                vertical-align: 25px !important;
                font-size: 18px !important;
            }
            .tit{
                overflow:hidden !important; 
                text-overflow:ellipsis !important; 
                white-space:nowrap !important;
                width: 50% !important; 
                vertical-align: 12px !important;
            }
                
            .txt{
                vertical-align: 38px !important;
            }
        }
        
    }
    @media (max-width: 780px) {
        h1{
            font-size: 25px !important;
        }
        .termSearchBox{
            text-align: center !important;
            padding-bottom: 0 !important;
            .searchSelectBox{
                width: 25% !important;
                .searchSelect{
                    font-size: 12px !important;
                }
            }
            .classificationBox{
                width: 15% !important;
                .searchSelect{
                    font-size: 12px !important;
                }
            }
            .searchBox{
                width: 50% !important;
            }
            .searchBtn{
                margin-left: 0px !important;
                margin-top: 15px;
                width: 465px !important;
                display: inline-block !important;
            }
        }
        .termListBox{
            margin-top: 40px;
        }
        .wordList{
            margin-bottom: 0px;
            text-align: center !important;
            p{
                text-align: center !important;
                display: inline-block;
            }
            .all{
                font-size: 16px !important;
                margin-left: 10px !important;
            }
            .wordTit{
                font-size: 14px !important;
            }
            .consonantBox{
                line-height: 30px;
                margin-left: 5px !important;
                .word{
                    margin-bottom: 10px;
                    width: 23px !important;
                    height: 23px !important;
                    line-height: 22px !important;
                    margin-left: 5px !important;
                    font-size: 12px !important;
                    cursor: pointer;
                }
            }
            p.on{
                color: #005b8f;
                font-weight: 700;
            }
        }
        .termList{
            overflow: hidden;
            width: 90% !important; 
            height: 100px !important;
            line-height: 60px !important;
            padding: 10px 20px !important;
            position: relative;
            .wordBox{
                width: 30px !important;
                height: 30px !important;
                font-size: 16px !important;
                vertical-align: 0px !important;
            }
            .tit{ 
                width: 350px !important;
                overflow:hidden !important; 
                text-overflow:ellipsis !important; 
                white-space:nowrap !important;
                position: absolute !important;
                left: 50px;
                top: 0;
                font-size: 16px !important;
                vertical-align: 15px;
                &::before{
                    display: none !important;
                }
            }
            .txt{
                padding-left: 0 !important;
                display: block !important;
                position: absolute;
                left: 50px;
                bottom: 0px !important;
                font-size: 14px !important;
                vertical-align: 15px !important;
            }
        }
    }
    @media (max-width: 400px) {
        h1{
            font-size: 22px !important;
        }
        .termSearchBox{
            text-align: center !important;
            padding-bottom: 20px !important;
            .searchSelectBox{
                margin-left: 0 !important;
                width: 25% !important;
                height: 50px;
                border-radius: 4px;
                margin-right: 10px;
                .searchSelect{
                    height: 50px;
                }
            }
            .searchBox{
                width: 50% !important;
                display: inline-block;
            }
            .searchBtn{
                margin-top: 15px;
                width: 380px !important;
                display: inline-block !important;
                color: #fff;
                text-align: center;
            }
            .wordList{
                margin-bottom: 0px;
                text-align: center !important;
                p{
                    text-align: center !important;
                    display: inline-block;
                }
                .all{
                    font-size: 16px !important;
                    margin-left: 10px !important;
                }
                .wordTit{
                    font-size: 14px !important;
                }
                .consonantBox{
                    line-height: 30px;
                    margin-left: 0 !important;
                    .word{
                        margin-bottom: 10px;
                        width: 21px !important;
                        height: 21px !important;
                        line-height: 21px !important;
                        margin-left: 5px !important;
                        font-size: 12px !important;
                        cursor: pointer;
                    }
                }
                p.on{
                    color: #005b8f;
                    font-weight: 700;
                }
            }
        }
        
        .termList{
            text-align: left;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 20px;
            display: inline-block;
            width: 370px !important; 
            height: 95px !important;
            line-height: 60px;
            padding: 20px;
            margin-bottom: 20px;
            .wordBox{
                width: 30px !important;
                height: 30px !important;
                display: inline-block;
                font-weight: 700;
                font-size: 16px;
                background-color: #005b8f;
                border-radius: 50%;
                position: relative;
                vertical-align: 0px !important;
                span{
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit{
                text-overflow : ellipsis;
                white-space: nowrap; 
                position: absolute !important;
                left: 50px;
                top: 0;
                display: inline-block;
                font-size: 16px !important;
                font-weight: 700;
                margin-left: 10px !important;
                border: 0 !important;
                position: relative;
                width: 300px !important;
                overflow: hidden;
            }
            .txt{
                font-size: 12px !important;
                left: 45px !important;
                bottom: 0px;
                vertical-align: 4px;
            }
        }
    }
    

}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #005b8f !important;
}

