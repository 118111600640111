.downloadBox{
    margin-bottom: 120px;
    .title{
        margin-left: 20px;
        margin-top: 60px;
        text-align: left;
    }
    .titBox{
        margin-left: 20px;
        h2{
            font-size: 20px;
            font-weight: 700;
        }
        .txt{
            margin-top: 20px;
        }
    }
    .linkBox{
        text-align: center;
        .downloadBtn{
            cursor: pointer;
            margin-top: 40px;
            display: inline-block;
            padding: 15px 50px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
        .youtubeLinkBox{
            margin-top: 100px;
            .youtubeLink{
                display: inline-block;
                width: 890px;
                height: 500px;
                background-color: #f1f1f1;
                margin-bottom: 40px;
            }
        }
        

    }
    @media (max-width: 1100px) {
        .linkBox{
            .youtubeLinkBox{
                .youtubeLink{
                    display: inline-block;
                    width: 70%;
                    height: 420px;
                }
            }
        }
    }
    @media (max-width: 750px) {
        .linkBox{
            .youtubeLinkBox{
                .youtubeLink{
                    width: 80%;
                    height: 300px;
                }
            }
        }
    }
    @media (max-width: 500px) {
        .linkBox{
            .youtubeLinkBox{
                .youtubeLink{
                    width: 90%;
                    height: 250px;
                }
            }
        }
    }
    @media (max-width: 400px) {
        .title{
            font-size: 22px !important;
        }
        .titBox{
            h2{
                font-size: 18px !important;
                font-weight: 700;
            }
            .txt{
                font-size: 14px !important;
            }
        }
        .linkBox{
            text-align: left;
            .downloadBtn{
                margin-left: 20px;
                padding: 15px 40px !important;
                font-size: 14px !important;
            }
            .youtubeLinkBox{
                margin-top: 70px;
                text-align: center;
                .youtubeLink{
                    display: inline-block;
                    width: 95%;
                    height: 200px;
                }
            }
            
    
        }
    }
    
}