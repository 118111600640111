.news{
    .sidebar{
        float: left;
        padding-top: 60px;
        width: 35%;
        letter-spacing: -0.5px;
        line-height: 1;
        font-size: 16px;
        font-weight: 400;
        height: 850px;
        background-color: #fff;
        h1{
            padding-left: 40%;
            height: 50px;
            line-height: 50px;
            border-radius: 40px;
            font-size: 25px;
            color: #005b8f !important;
            font-weight: 700;
            margin-bottom: 40px;
        }
        .sideBox{
            margin-left: 40%;
            .menusTit{
                font-size: 16px;
                padding: 10px 0;
                margin: 20px 0;
                color: #1b1b1b;
                font-weight: 700;
                transition: padding .4s ease;
            }
            .menusTit:hover{
                padding: 10px 15px;
            }
            .menusTit.on{
                color: #005b8f;
                padding: 10px 15px !important;
            }
        }
    }
    @media (max-width: 780px) {
        .sidebar{
            float: none !important;
            width: 100% !important;
            height: auto !important;
            padding-bottom: 20px;
            padding-top: 20px !important;
            h1{
                font-size: 22px !important;
                padding-left: 40px !important;
                margin-bottom: 0px !important; 
            }
            .sideBox{
                margin-left: 45px !important;
                .menusTit{
                    margin-right: 40px !important;
                }
            }
            
        }
    }
    @media (max-width: 400px) {
        .sidebar{
            h1{
                font-size: 18px !important;
                padding-left: 35px !important;
            }
            .sideBox{
                margin-left: 35px !important;
            }
        }
    }
}