.introduction{
    .sidebar{
        float: left;
        padding-top: 60px;
        width: 35%;
        letter-spacing: -0.5px;
        line-height: 1;
        font-size: 16px;
        font-weight: 400;
        height: 1000px;
        background-color: #fff;
        h1{
            padding-left: 40%;
            height: 50px;
            line-height: 50px;
            border-radius: 40px;
            font-size: 25px;
            color: #005b8f !important;
            font-weight: 700;
            margin-bottom: 40px;
        }
        .sideBox{
            margin-left: 40%;
            .menusTit{
                font-size: 16px;
                padding: 10px 0;
                margin: 20px 0;
                color: #1b1b1b;
                font-weight: 700;
                transition: padding .4s ease;
            }
            .menusTit:hover{
                padding: 10px 15px;
            }
            .menusTit.on{
                padding: 10px 15px;
                color: #005b8f;
            }
        }
    }
    .contBox{
        margin-bottom: 80px;
        float: left;
        margin-top: 15px;
        margin-left: 33px;
        width: 55%;
        border-radius: 20px;
        position: relative;
        .contTitle{
            color: #666;
            font-weight: 500;
            font-size: 22px;
            margin: 40px 20px 40px;
        }
        .tit{
            margin-top: 40px;
            margin-left: 20px;
            font-weight: 700 !important;
            font-size: 20px;
            font-weight: 500;
        }
        .txtBox{
            padding: 40px 20px 20px;
            width: 100%;
            height: auto;
            line-height: 30px;
            margin-bottom: 60px;
        }
        // .imgBox{
        //     z-index: -10;
        //     position: absolute;
        //     right: -225px;
        //     bottom: 0;
        // }
        .menusImg{
            display: none;
        }
        .menusImg.on{
            display: block;
        }
    }
    .inner::after{
        content: "";
        display: block;
        clear: both;
    }
    @media (max-width: 1100px) {
        h2{
            font-size: 20px !important;
        }
    }
    @media (max-width: 780px) {
        .eyhanyoungBox{
            float: none !important;
            width: 100% !important;
            margin-left: 0 !important;
        }
    }
    @media (max-width: 780px) {
        .eyhanyoungBox{
            h2{
                margin: 40px 35px;
            }
        }
    }
    @media (max-width: 1100px) {
        .sidebar{
            width: 30% !important;
            h1{
                padding-left: 13.5% !important;
            }
            .sideBox{
                margin-left: 13.5% !important;
                .menusTit{
                    font-size: 14px !important;
                }
            }
        }
    }
    @media (max-width: 780px) {
        .sidebar{
            float: none !important;
            width: 100% !important;
            padding-bottom: 20px;
            height: auto !important;
            padding-top: 20px !important;
            h1{
                font-size: 22px !important;
                padding-left: 45px !important;
                margin-bottom: 0px !important; 
            }
            .sideBox{
                margin-left: 45px !important;
                .menusTit{
                    margin-right: 40px !important;
                }
            }
            
        }
    }
    @media (max-width: 400px) {
        .sidebar{
            h1{
                font-size: 18px !important;
                padding-left: 35px !important;
            }
            .sideBox{
                margin-left: 35px !important;
            }
        }
    }
    .contBox{
        float: left;
        margin-top: 15px;
        margin-left: 40px;
        width: 55%;
        h2{
            font-size: 25px;
            margin: 35px 0;
            font-weight: 700;
        }
    }
    .inner::after{
        content: "";
        display: block;
        clear: both;
    }
    @media (max-width: 1100px) {
        h2{
            font-size: 20px !important;
        }
    }
    @media (max-width: 780px) {
        .contBox{
            padding: 0 10px;
            float: none !important;
            width: 100% !important;
            margin-left: 0 !important;
            h2{
                margin-left: 10px !important;
            }
        }
    }
    @media (max-width: 400px) {
        .contBox{
            h2{
                margin-left: 20px !important;
                font-size: 18px !important;
            }
            .txtBox{
                padding-top: 0;
                margin-top: 40px;
                font-size: 14px !important;
            }
        }
    }
}
.introduction::after{
    content: "";
    display: block;
    clear: both;
}