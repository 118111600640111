.BoardViewPost{
    line-height: 24px;
    .boardMid {
      min-height: 560px;
      .table-responsive table{
          min-height: 0;
          tr{
            height: 45px;
          }
      }
    }
    .backBtn{
      margin-bottom: 100px;
    }
    @media (max-width: 650px) {
      .boardMid {
        padding-left: 3%;
        padding-right: 3%;
        min-height: 460px;
        .table-responsive table{
            font-size: 0.8em;
            min-height: 0;
            tr{
              height: 20px;
            }
        }
      }
    }
    .categoreNm{
      color: rgb(110, 110, 110);
    }
    .boardInfo{
      color: rgb(110, 110, 110);
      font-size: 0.8em;
    }
    .boardContact{
      height: 490px;
      overflow-y: auto;
      img{
        width: 100%;
      }
    }
    .backBtn{
      vertical-align: 13px;
      cursor: pointer;
      padding: 13px 35px;
      border-radius: 2px;
      color: #fff;
      font-weight: 700;
      background-color: #005b8f;
      text-align: center;
      float: right;
      width: 100%;
    }
  }
@media (max-width: 400px) {
  .backBtn{
      margin-bottom: 120px;
  }
}