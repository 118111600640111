.queBox{
    float: left;
    margin-top: 15px;
    margin-left: 40px;
    width: 55%;
    margin-bottom: 120px;
    h2{
        font-size: 25px;
        margin: 35px 0px;
        font-weight: 700;
    }
    .queMainBox{
        border-top: 2px solid #005b8f;
        .inputBox{
            margin-top: 30px;
            .titleInput {
                width: 58.5%;
                margin-top: 5px;
            }
            .emailInput {
                width: 35%;
                margin-left: 10px;
                font-size: 12px;
                margin-top: 5px;
            }
            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-inpu{
                font-size: 20px;
            }
            .titleInput::placeholder{
                font-size: 10px;
            }
            .contentTex {
                height: 370px;
                width: 96.5%;
                margin-top: 15px;
                border-radius: 5px;
                border: solid 1px silver;
                resize: none;
                padding: 13px;
            }
            .sendBtn{
                margin-top: 40px;
                vertical-align: 13px;
                cursor: pointer;
                padding: 20px 35px;
                border-radius: 2px;
                color: #fff;
                font-weight: 700;
                background-color: #005b8f;
                text-align: center;
                font-size: 17px;
                width: 96.5%;
              }
        }
    }
}
.inner::after{
    content: "";
    display: block;
    clear: both;
}
@media (max-width: 1100px) {
    h2{
        font-size: 20px !important;
    }
}
@media (max-width: 780px) {
    h2{
        font-size: 18px !important;
        
    }
    .queBox{
        padding: 0 10px;
        float: none !important;
        width: 100% !important;
        margin-left: 0 !important;
        h2{
            margin-left: 20px !important;
        }
        .inputBox{
            h5 {
                display: none;
            }
        }
    }
}
@media (max-width: 400px) {
    .queBox{
        h2{
            margin-left: 10px !important;
        }
    }
}