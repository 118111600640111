.appBar{
    background-color: #005b8f !important;
    padding: 0 20px;
    box-shadow: none !important;
    color: #1b1b1b !important;
    .headerLf{
        position: relative;
        .menuBox{
            position: relative;
            height: 80px;
            .oneD{
                position: relative;
                width: 220px;
                margin: 0;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                line-height: 80px;
                .twoD{
                    font-size: 16px;
                    font-weight: 400;
                    position: absolute;
                    left: 50%;
                    top: 100px;
                    transform: translateX(-50%);
                    z-index: 9999;
                    display: none;
                    li{
                        white-space:nowrap;
                        line-height: 50px;
                        margin-bottom: 20px;
                        color: #000;
                        transition: all .2s ease;
                    }
                    li:hover{
                        text-decoration: underline;
                        color: #005b8f;
                        font-weight: 700;
                    }
                }
            }
            .menuItemBg{
                position: absolute;
                left: 0;
                top: 80px;
                width: 770px;
                height: 225px;
                background-color: #fff;
                z-index: 9998;
                border: solid 1px rgb(221, 221, 221);
                display: none;
                border-radius: 0 0 10px 10px;
                transition: all .4s ease;
            }        
        }
        .menuBox:hover{
            .oneD{
                .twoD{
                    display: block;
                }
            }
            .menuItemBg{
                display: block;
            }
        }
    }
    .headerRig{
        float: right;
        line-height: 60px;
        .img{
            margin: 0 15px;
            width: 100px;
            height: 50px;
            background-color: #000;
        }
        .img:last-child{margin-right: 0;}
    }
    
    .inner::after{
        content: "";
        display: block;
        clear: both;
    }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    box-shadow: none !important;
    height: auto;
}
.logo{
    padding-top: 8px;
    cursor: pointer;
    margin-left: 0 !important;
    overflow: inherit;
}
.mMenuBtn{
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    display: none;
}
.mBox{
    width: 100%;
    position: absolute;
    left: 0;
    top: 80px !important;
    background-color: #fff;
    z-index: 9999;
    display: none;
    .mOneD{
        cursor: pointer;
        padding: 20px 0;
        margin: 10px 0;
        color: #1b1b1b;
        font-size: 18px !important;
        font-weight: 700 !important;
        text-align: center !important;
    }
    .mOneD:hover{
        color: #005b8f;
    }
}
@media (max-width: 1100px) {
    .appBar{
        padding: 0 20px !important;
    }
}
@media (max-width: 899px) {
    .appBar{
        padding: 0 !important;
    }
    .mMenuBtn{
        display: block;
    }
    .mBox{
        display: block;
    }
    .mLogo{
        text-align: center;
    }
@media (max-width: 600px) {
    .appBar{
        padding: 0 !important;
    }
    .mBox{
        top: 56px;
    }
}
}
.css-1cndkqf-MuiToolbar-root{
    min-height: 80px !important;
}


