.termsDetailBox{
    margin-top: 60px;
    margin-bottom: 120px;
    legend,fieldset{line-height: 0 !important; visibility: hidden;}
    h1{
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }
    .mainBox{
        text-align: center;
        background-color: #fff;
        padding-top: 40px;
        margin-top: 40px;
        border-radius: 4px;
        height: auto;
        .termList{
            text-align: left;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 20px;
            display: inline-block;
            height: auto;
            width: 1000px;
            line-height: 60px;
            padding: 30px 50px 80px;
            margin-bottom: 40px;
            position: relative;
            .wordBox{
                width: 40px;
                height: 40px;
                display: inline-block;
                font-weight: 700;
                font-size: 20px;
                background-color: #005b8f;
                border-radius: 50%;
                position: relative;
                vertical-align: -11px;
                span{
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit{
                width: 580px;
                display: inline-block;
                font-size: 18px;
                font-weight: 700;
                margin-left: 15px;
                position: relative;
                padding-right: 15px;
                //border-right: solid 1px #eaeaea;
                &::before{
                    content: "";
                    display: block;
                    width: 1px;
                    height: 25px;
                    background-color: #e0e0e0;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .txt{
                position: absolute;
                right: 50px;
                display: inline-block;
                margin-left: 30px;
                vertical-align: 4px;
                font-size: 14px;
                font-weight: 500;
            }
            .termContact{
                margin-top: 20px;
                font-size: 14px;
                line-height: 24px;
                height: auto;
                p{
                    word-break:break-all;
                }
                a{
                    font-size: 10px;
                    display: inline-block;
                    width: auto;
                }
            }  
        }
        .backBtn{
          height: 50px;
          width: 1000px;
          border-radius: 4px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          margin: 0 auto;
          font-size: 16px;
          font-weight: 500;
          line-height: 50px;
          color: #666;
          transition: all ease 0.25s;
          cursor: pointer;
        }
        .backBtn:hover{
          color: #1b1b1b;
        }
    }
    @media (max-width: 1100px) {
        .termList{
            width: 93% !important;
            .wordBox{
                vertical-align: -2px !important;
                font-size: 18px !important;
            }
            .tit{
                width: 559px !important;
                padding-right: 0 !important;
                vertical-align: 9px;
                &::before{
                    display: none !important;
                }
            }
                
            .txt{
                position: inherit !important;
                line-height: 18px !important;
                margin-left: 105px !important;
                display: block !important;
                font-size: 14px !important;
                vertical-align: 11px !important;
            }
        }
        .backBtn{
            width: 90% !important;
        }
    }
    @media (max-width: 780px) {
        h1{
            font-size: 25px !important;
        }
        .termList{
            width: 90% !important; 
            height: auto !important;
            line-height: 60px !important;
            padding: 20px 20px 80px !important;
            position: relative;
            .wordBox{
                width: 35px !important;
                height: 35px !important;
                font-size: 18px !important;
                vertical-align: top !important;
            }
            .tit{
                vertical-align: 12px !important;
                width: 300px !important;
                line-height: 25px !important;
                font-size: 16px !important;
                &::before{
                    display: none !important;
                }
            }
            .txt{
                line-height: 25px !important;
                margin-left: 100px !important;
                display: block !important;
                font-size: 12px !important;
                vertical-align: 15px !important;
            }
        }
    }
    @media (max-width: 400px) {
        h1{
            font-size: 22px !important;
        }
        .termList{
            text-align: left;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 20px;
            display: inline-block;
            width: 350px !important; 
            height: auto;
            line-height: 60px;
            margin-bottom: 20px !important;
            padding: 20px 20px 80px !important;
            position: relative !important;
            .wordBox{
                width: 35px !important;
                height: 35px !important;
                display: inline-block;
                font-weight: 700;
                font-size: 20px;
                background-color: #005b8f;
                border-radius: 50%;
                position: relative;
                vertical-align: top !important;
                span{
                    color: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .tit{
                line-height: 18px;
                word-break:break-all;
                display: inline-block;
                font-size: 14px !important;
                font-weight: 700;
                margin-left: 10px !important;
                width: 250px !important;
                border: 0 !important;
                position: relative;
                padding-right: 0 !important;
            }
            .txt{
                display: block !important;
                font-size: 12px !important;
            }
        }
        .backBtn{
            font-size: 14px !important;
            height: 40px !important;
            line-height: 40px !important;
        }
    }
}