.termPDFDownloadBox{
    margin-top: 60px;
    text-align: center;
    h1{
        font-size: 25px;
        font-weight: 700;
    }
    .tit{
        font-size: 18px;
        font-weight: 700;
        display: inline-block;
        margin-top: 40px;
    }
    .txtBox{
        margin-top: 60px;
        background-color: #f1f1f1;
        border-radius: 4px;
        padding: 40px;
        p{ 
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 40px;
            font-weight: 500;
            span{
                font-size: 18px;
            }
        }
        .PDFDownloadBtn{
            cursor: pointer;
            margin-top: 20px;
            display: inline-block;
            padding: 15px 70px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
       
    }
    .downloadPage{
        margin-top: 40px;
        margin-bottom: 160px;
        .txt{
            margin-top: 20px;
            font-weight: 500;
            line-height: 24px;
        }
        .applyBtn{
            cursor: pointer;
            margin-top: 20px;
            display: inline-block;
            padding: 15px 50px;
            border-radius: 4px;
            color: #fff;
            font-weight: 700;
            background-color: #005b8f;
        }
    }
   
  
}
@media (max-width: 780px) {
    .termPDFDownloadBox{
        text-align: center !important;
        h1{
            margin-left: 20px !important;
        }
        .tit{
            margin-left: 20px !important;
        }
        .txtBox{
            p{ 
                font-size: 16px !important;
                line-height: 25px;
                span{
                    font-size: 14px;
                }
            }
        }
        .downloadPage{
            margin-top: 40px;
            margin-bottom: 160px;
            text-align: center;
            .txt{
                padding: 0 40px;
                margin-top: 20px;
                line-height: 25px;
                font-size: 14px;
            }
        }
    }
}
@media (max-width: 400px) {
    .termPDFDownloadBox{
        h1{
            margin-left: 10px !important;
            font-size: 22px !important;
        }
        .tit{
            margin-left: 10px !important;
            font-size: 16px !important;
        }
        .txtBox{
            padding: 30px 15px !important;
            p{ 
                margin-bottom: 20px !important; 
            }
        }
        .downloadPage{
            margin-top: 40px !important;
            margin-bottom: 160px !important;
            text-align: center;
            .txt{
                font-size: 12px;
            }
            .applyBtn{
                padding: 15px 45px;
                font-size: 14px;
            }
        }
    }
}
.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 44px;
    
}
.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3px 4px 7.5px 6px !important;
}


